import styled from "styled-components";

const EvilMushroom = () => <Wrap />;

export default EvilMushroom;

const Wrap = styled.div`
  width: 50px;
  height: 50px;

  @keyframes evil-mushroom {
    0% {
      background-image: url(./images/svg/evil-mushroom-step-left.svg);
    }
    25% {
      background-image: url(./images/svg/evil-mushroom-step-left.svg);
    }

    25.1% {
      background-image: url(./images/svg/evil-mushroom-step-right.svg);
    }
    50% {
      background-image: url(./images/svg/evil-mushroom-step-right.svg);
    }

    50.1% {
      background-image: url(./images/svg/evil-mushroom-step-left.svg);
    }
    75.0% {
      background-image: url(./images/svg/evil-mushroom-step-left.svg);
    }
    75.1% {
      background-image: url(./images/svg/evil-mushroom-step-right.svg);
    }
    100% {
      background-image: url(./images/svg/evil-mushroom-step-right.svg);
    }
  }

  background-size: 100%;
  background-repeat: no-repeat;
  animation: evil-mushroom 1s linear infinite forwards;
`;
