import { useStoreMe, setStoreMe, getStoreMe } from "store-me";
import styled from "styled-components";
import { useEffect } from "react";
import cn from "classnames";

import _constants from "./_constants";
import {
  generateState,
  recursiveCreateUniqueCandle,
} from "../utils/chartUtils";

const ChartAnimation = () => {
  const { grid, colorsByColumn, candleFitilByColumn, moveCandles, addCandle } =
    useStoreMe(
      "grid",
      "colorsByColumn",
      "candleFitilByColumn",
      "moveCandles",
      "addCandle"
    );

  useEffect(() => {
    if (addCandle) {
      const { grid, gridRows, colorsByColumn, candleFitilByColumn } =
        getStoreMe("grid", "gridRows", "colorsByColumn", "candleFitilByColumn");
      const newCandle = recursiveCreateUniqueCandle(
        grid,
        gridRows,
        grid.length
      );
      const updatedGrid = [...grid.slice(1, grid.length), newCandle];

      setStoreMe({
        ...generateState(
          updatedGrid,
          false,
          colorsByColumn,
          candleFitilByColumn
        ),
        moveCandles: false,
      });
    }
  }, [addCandle]);

  useEffect(() => {
    if (!moveCandles) {
      const timeout = setTimeout(() => {
        setStoreMe({ moveCandles: Date.now() });
      }, _constants.pauseBetweenChartAnimationsMS);

      return () => clearTimeout(timeout);
    }
  }, [moveCandles]);

  return (
    <Wrap>
      <GridWrap>
        <GridInner
          className={cn({ "move-animation": moveCandles })}
          onAnimationEnd={({ animationName }) => {
            if (animationName === "move-animation") {
              setStoreMe({ addCandle: Date.now() });
            }
          }}
        >
          {grid.map((column, columnIndex) => (
            <Column
              className={cn({
                "is-green": colorsByColumn[columnIndex] === "green",
                "is-red": colorsByColumn[columnIndex] === "red",
              })}
              candleStickHeight={candleFitilByColumn[columnIndex].height}
              candleStickTop={candleFitilByColumn[columnIndex].startIndex}
              key={columnIndex}
            >
              {column.map((block, blockIndex) => (
                <Block
                  className={cn({
                    "is-green":
                      block && colorsByColumn[columnIndex] === "green",
                    "is-red": block && colorsByColumn[columnIndex] === "red",
                  })}
                  key={blockIndex}
                />
              ))}
            </Column>
          ))}
        </GridInner>
      </GridWrap>
    </Wrap>
  );
};

export default ChartAnimation;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const GridWrap = styled.div`
  position: relative;
  overflow: hidden;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: repeating-linear-gradient(#fff, #fff 43px, #dfdfdf 44px);
`;

const GridInner = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${_constants.candleBlockSize}px;
  padding-bottom: ${_constants.candleBlockSize}px;

  &.move-animation {
    animation: move-animation ${_constants.chartAnimationDurationMS}ms linear
      forwards;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: ${_constants.candleBlockSize + _constants.candleDistance}px;
  min-width: ${_constants.candleBlockSize + _constants.candleDistance}px;

  &:before {
    content: "";
    position: absolute;
    left: -1px;
    top: -${_constants.candleBlockSize}px;
    width: 1px;
    height: calc(100% + ${_constants.candleBlockSize * 2}px);
    background-color: #ccc;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0px;
    width: 1px;
    height: ${({ candleStickHeight }) =>
      candleStickHeight * _constants.candleBlockSize}px;
    top: ${({ candleStickTop }) =>
      candleStickTop * _constants.candleBlockSize}px;
  }

  &.is-green {
    &:after {
      background-color: #71be11;
    }
  }

  &.is-red {
    &:after {
      background-color: #e04b0e;
    }
  }
`;

const Block = styled.div`
  width: ${_constants.candleBlockSize}px;
  height: ${_constants.candleBlockSize}px;

  &.is-green {
    background-color: #71be11;
  }

  &.is-red {
    background-color: #e04b0e;
  }
`;
